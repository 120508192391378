import { useMsal } from '@azure/msal-react';
// @mui material components
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/MKBox';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';

// Author page sections
import Profile from 'pages/LandingPages/Author/sections/Profile';
import Posts from 'pages/LandingPages/Author/sections/Posts';

// Routes
import authRoutes from 'authRoutes';
import footerRoutes from 'footer.routes';

// Images
import bgImage from 'assets/images/city-profile.jpg';
import blackLogoImage from 'assets/images/logos/gray-logos/black_favicon.png';

import { useEffect, useState } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';
import Leaderboard from './sections/Leaderboard';

function Author() {
  const { instance } = useMsal();
  const [userData, setUserData] = useState({
    photoUrl: '',
    designation: '',
    email: '',
    officeLocation: '',
    userName: '',
  });

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.error(error));
  };

  const prefersDarkMode =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const request = {
    scopes: ['user.read', 'profile', 'openid'],
  };

  useEffect(() => {
    const handleProfileRequest = async () => {
      try {
        const resToken = await instance.acquireTokenSilent(request);
        const profileResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
          headers: {
            Authorization: `Bearer ${resToken.accessToken}`,
          },
        });

        const profileData = await profileResponse.json();
        // Update user data from profile
        setUserData((prevUserData) => ({
          ...prevUserData,
          email: profileData.mail,
          userName: profileData.displayName,
          designation: profileData.jobTitle,
        }));

        const response = await fetch(
          'https://graph.microsoft.com/v1.0/users/22372f10-8c95-43b2-9e04-febce106e53c/photo/$value',
          {
            headers: {
              Authorization: `Bearer ${resToken.accessToken}`,
            },
          }
        );

        if (response.ok) {
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          // Update user data with profile picture URL
          setUserData((prevUserData) => ({ ...prevUserData, photoUrl: imageUrl }));
        } else {
          console.error('Failed to fetch user photo:', response.status);
        }
      } catch (error) {
        console.error('Error fetching user photo:', error);
      }
    };
    handleProfileRequest();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={authRoutes}
        action={{
          type: 'function',
          label: 'sign out',
          color: 'info',
          click: handleLogoutRedirect,
        }}
        light={prefersDarkMode}
        brand={blackLogoImage}
        brandType="img"
        sticky
      />
      <MKBox>
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'grid',
            placeItems: 'center',
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { dark, white }, functions: { rgba } }) =>
              prefersDarkMode ? rgba(dark.focus, 0.8) : rgba(white.focus, 0.8),
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile
            name={userData.userName}
            profilePicture={userData.photoUrl}
            designation={userData.designation}
            email={userData.email}
            officeLocation={userData.officeLocation}
          />
          <Leaderboard />
          <Posts />
        </Card>
        {/* <Contact /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default Author;

Author.propTypes = {
  axiosInstance: PropTypes.object,
};
