import { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Kit 2 React themes
import theme from 'assets/theme';
import Presentation from 'layouts/pages/presentation';

// Material Kit 2 React routes
import authRoutes from 'authRoutes';
import Registration from 'pages/Registration/Registration';
import interceptor from 'utils/interceptor';

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const { instance } = useMsal();
  const activateAccount = instance.getActiveAccount();

  localStorage.setItem('activateAccount', JSON.stringify(activateAccount));
  const axiosInstance = interceptor(activateAccount?.idToken);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticatedTemplate>
        {activateAccount ? (
          <Routes>
            {getRoutes(authRoutes)}
            <Route path="/presentation" element={<Presentation axiosInstance={axiosInstance} />} />
            <Route path="*" element={<Navigate to="/presentation" />} />
            <Route path="/registration" element={<Registration axiosInstance={axiosInstance} />} />
          </Routes>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/presentation" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/presentation" />} />
        </Routes>
      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
}
