// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React components
import TransparentBlogCard from 'examples/Cards/BlogCards/TransparentBlogCard';

// Images
import post1 from 'assets/images/examples/testimonial-6-2.jpg';
import post2 from 'assets/images/examples/testimonial-6-3.jpg';
import post3 from 'assets/images/examples/blog-9-4.jpg';

function Places() {
  // const [componentList, setComponentList] = useState([]);
  const dummyComponent = [
    {
      image: post1,
      title: 'Login Component',
      description:
        'A login component allows users to authenticate their identity to access a system, typically requiring a username and password.',
    },
    {
      image: post3,
      title: 'Login Component',
      description:
        'A login component allows users to authenticate their identity to access a system, typically requiring a username and password.',
    },
    {
      image: post2,
      title: 'Login Component',
      description:
        'A login component allows users to authenticate their identity to access a system, typically requiring a username and password.',
    },
  ];
  // setComponentList(dummyComponent);

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            My components.
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {dummyComponent
            ? dummyComponent.map((component, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <TransparentBlogCard
                    image={post1}
                    title={component.title}
                    description={component.description}
                    action={{
                      type: 'internal',
                      route: '#',
                      color: 'info',
                      label: 'read more',
                    }}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
