import axios from 'axios';

const interceptor = (token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
  });

  instance.interceptors.request.use(
    (req) => {
      // Add configurations here
      req.headers['Authorization'] = `Bearer ${token}`;
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      res.headers['Authorization'] = `Bearer ${token}`;
      // Add configurations here
      if (res.status === 201) {
        console.log('Posted Successfully');
      }
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return instance;
};

export default interceptor;
