// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Icon from '@mui/material/Icon';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKAvatar from 'components/MKAvatar';
import MKTypography from 'components/MKTypography';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

const Profile = ({ name, profilePicture, designation }) => {
  return (
    <MKBox component="section" pt={{ xs: 6, sm: 12 }} pb={0}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={7} mx={{ xs: 'auto', sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3" color="text">
                  {name}
                </MKTypography>
              </MKBox>
              <Grid container spacing={3} mb={3}>
                <Grid item md={12}>
                  <MKTypography variant="body2" color="secondary">
                    {designation}
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography variant="body2">{}</MKTypography>
                </Grid>
              </Grid>
              <MKTypography variant="body1" fontWeight="light" color="text">
                {}

                <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: 'max-content',
                    display: 'flex',
                    alignItems: 'center',

                    '& .material-icons-round': {
                      transform: `translateX(3px)`,
                      transition: 'transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)',
                    },

                    '&:hover .material-icons-round, &:focus .material-icons-round': {
                      transform: `translateX(6px)`,
                    },
                  }}
                ></MKTypography>
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

Profile.defaultProps = {
  name: '',
  profilePicture: '',
  email: '',
  designation: '',
  officeLocation: '',
};

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  profilePicture: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  email: PropTypes.string,
  officeLocation: PropTypes.string,
};

export default Profile;
