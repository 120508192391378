/**
 * The base colors for the Material Kit 2 React.
 * You can add new color using this file.
 * You can customized the colors for the entire Material Kit 2 React using thie file.
 */

import darkColors from './darkColors';
import lightColors from './lightColors';

const prefersDarkMode =
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

let colors = darkColors;

if (!prefersDarkMode) {
  colors = lightColors;
}

export default colors;
