import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';
import FilledInfoCard from 'examples/Cards/InfoCards/FilledInfoCard';

// Presentation page sections
import Counters from 'pages/Presentation/sections/Counters';
import Information from 'pages/Presentation/sections/Information';
import DesignBlocks from 'pages/Presentation/sections/DesignBlocks';
import Pages from 'pages/Presentation/sections/Pages';
import Testimonials from 'pages/Presentation/sections/Testimonials';
import Download from 'pages/Presentation/sections/Download';

// Presentation page components
import BuiltByDevelopers from 'pages/Presentation/components/BuiltByDevelopers';

// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';

// css styles
import './Presentation.css';

// Images
import bgImage from 'assets/images/BG.jpg';
import blackLogoImage from 'assets/images/logos/gray-logos/black_favicon.png';

import authRoutes from 'authRoutes';

const Presentation = () => {
  const loginRequest = {
    scopes: [],
  };

  const { instance } = useMsal();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.error(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.error(error));
  };

  const prefersDarkMode =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  return (
    <>
      <AuthenticatedTemplate>
        <DefaultNavbar
          routes={authRoutes}
          action={{
            type: 'function',
            label: 'sign out',
            color: 'info',
            click: handleLogoutRedirect,
          }}
          light={prefersDarkMode}
          brand={blackLogoImage}
          brandType="img"
          sticky
        />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <DefaultNavbar
          routes={routes}
          action={{
            type: 'function',
            label: 'sign in',
            color: 'info',
            click: handleRedirect,
          }}
          light={prefersDarkMode}
          brand={blackLogoImage}
          brandType="img"
          sticky
        />
      </UnauthenticatedTemplate>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                  fontSize: size['3xl'],
                },
              })}
            >
              <h1 className="hero glitch layers banner-text" data-text="Play">
                <span>Play</span>
              </h1>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { dark, white }, functions: { rgba } }) =>
            prefersDarkMode ? rgba(dark.focus, 0.8) : rgba(white.focus, 0.8),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
        <Pages />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color={prefersDarkMode ? 'light' : 'dark'}
                bgColor={prefersDarkMode ? 'dark' : 'light'}
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: 'external',
                  route: 'https://documentation-wy30kq4fz-shubhrits-projects.vercel.app/',
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                icon="precision_manufacturing"
                color={prefersDarkMode ? 'light' : 'dark'}
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Play Kit."
                action={{
                  type: 'external',
                  route: '',
                  label: 'Read more',
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                icon="apps"
                color={prefersDarkMode ? 'light' : 'dark'}
                title="Components"
                description="Play Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: 'external',
                  route:
                    'https://documentation-wy30kq4fz-shubhrits-projects.vercel.app/advanced/loginPage',
                  label: 'Read more',
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Testimonials />
        <UnauthenticatedTemplate>
          <Download />
        </UnauthenticatedTemplate>
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12} ml="auto" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5} color="text">
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Presentation;
