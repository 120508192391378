import { useMsal } from '@azure/msal-react';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import DefaultFooter from 'examples/Footers/DefaultFooter';

// Routes
import authRoutes from 'authRoutes';
import footerRoutes from 'footer.routes';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Image
import bgImage from 'assets/images/illustrations/illustration-reset.jpg';
import blackLogoImage from 'assets/images/logos/gray-logos/black_favicon.png';
import { FormControl, Link } from '@mui/material';
import { useState } from 'react';

function Registration({ axiosInstance }) {
  const { instance } = useMsal();
  const [errorFormFlag, setErrorFormFlag] = useState({
    participantName: false,
    participantEmail: false,
    circleName: false,
    community: false,
  });

  const [formValues, setFormValues] = useState({
    participantName: '',
    participantEmail: '',
    circleName: '',
    community: '',
    reasonForParticipation: '',
  });

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.error(error));
  };
  const prefersDarkMode =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  const onFormValueChange = (e, keyName) => {
    setErrorFormFlag({
      ...errorFormFlag,
      [keyName]: e.target.value === '' ? true : false,
    });

    setFormValues((prevFormValues) => ({ ...prevFormValues, [keyName]: e.target.value }));
  };

  const submitData = () => {
    if (
      formValues.participantName === '' ||
      formValues.participantEmail === '' ||
      formValues.circleName === '' ||
      formValues.community === ''
    ) {
      return;
    }

    const payload = formValues;
    console.log(payload);
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASE_URL}/register-participant`, payload)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };

  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={authRoutes}
          action={{
            type: 'function',
            label: 'sign out',
            color: 'info',
            click: handleLogoutRedirect,
          }}
          light={prefersDarkMode}
          brand={blackLogoImage}
          brandType="img"
          sticky
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: 'none', lg: 'flex' }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: 'auto', lg: 6 }}
          mr={{ xs: 'auto', lg: 6 }}
        >
          <MKBox
            sx={{
              backgroundColor: ({ palette: { dark, white }, functions: { rgba } }) =>
                prefersDarkMode ? rgba(dark.focus, 0.8) : rgba(white.focus, 0.8),
              backdropFilter: 'saturate(200%) blur(30px)',
            }}
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="text">
                Registration
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                To register, please complete the form below. An email will be sent to you for
                confirmation.{' '}
                <Link
                  href="https://apps.powerapps.com/play/e/default-d7758e8f-1df3-489f-86b5-a2254f55f9cc/a/0305d7a0-fe68-4487-8f1e-c00d1b9935e9"
                  target="_blank"
                  rel="noreferrer"
                  fontWeight="regular"
                  color="inherit"
                  variant="body2"
                >
                  Click Here
                </Link>{' '}
                to know your circle.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <FormControl>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        label="Full Name"
                        variant="standard"
                        error={errorFormFlag.participantName}
                        success={!errorFormFlag.participantName}
                        onChange={(e) => onFormValueChange(e, 'participantName')}
                        onFocus={(e) => onFormValueChange(e, 'participantName')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        error={errorFormFlag.participantEmail}
                        success={!errorFormFlag.participantEmail}
                        onChange={(e) => onFormValueChange(e, 'participantEmail')}
                        onFocus={(e) => onFormValueChange(e, 'participantEmail')}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Circle Name"
                        error={errorFormFlag.circleName}
                        success={!errorFormFlag.circleName}
                        onChange={(e) => onFormValueChange(e, 'circleName')}
                        onFocus={(e) => onFormValueChange(e, 'circleName')}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Community"
                        error={errorFormFlag.community}
                        success={!errorFormFlag.community}
                        onChange={(e) => onFormValueChange(e, 'community')}
                        onFocus={(e) => onFormValueChange(e, 'community')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Reason for Participation"
                        multiline
                        fullWidth
                        className="input-field"
                        onChange={(e) => onFormValueChange(e, 'reasonForParticipation')}
                        onFocus={(e) => onFormValueChange(e, 'reasonForParticipation')}
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton type="button" variant="gradient" color="info" onClick={submitData}>
                      Register
                    </MKButton>
                  </Grid>
                </FormControl>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Registration;

Registration.propTypes = {
  axiosInstance: PropTypes.object,
};
