// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-router-dom components

// @mui material components
import Icon from '@mui/material/Icon';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

function RankCard({ variant, bgColor, color, icon, title }) {
  let iconColor = color;

  if (variant === 'gradient' && color !== 'light') {
    iconColor = 'white';
  } else if (variant === 'gradient' && color === 'light') {
    iconColor = 'dark';
  }

  return (
    <MKBox
      display={{ xs: 'block', md: 'flex' }}
      variant={variant}
      bgColor={bgColor}
      borderRadius="xl"
      pt={3.5}
      pb={3}
      px={3}
      mb={1}
      alignItems="center" // Align items to the center horizontally
    >
      <MKTypography display="block" variant="h3" color={iconColor} mt={-0.625}>
        {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
      </MKTypography>
      <MKBox pt={{ xs: 3, md: 0 }} pl={{ xs: 0, md: 2 }} lineHeight={1}>
        <MKTypography display="block" variant="5" color={color} fontWeight="bold" mb={1}>
          {title}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the FilledInfoCard
RankCard.defaultProps = {
  variant: 'contained',
  color: 'info',
  action: false,
};

// Typechecking props for the FilledInfoCard
RankCard.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default RankCard;
