import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';

import App from 'App';
import { MsalProvider } from '@azure/msal-react';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig.msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().lengeth > 0) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </BrowserRouter>
);
