// @mui material components
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import RankCard from 'examples/Cards/RankCard/RankCard';

const Leaderboard = () => {
  const prefersDarkMode =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  const rankData = [
    { rank: 1, name: 'John' },
    { rank: 2, name: 'Alice' },
    { rank: 3, name: 'Michael' },
    { rank: 4, name: 'Emily' },
    { rank: 5, name: 'David' },
    { rank: 6, name: 'Sophia' },
    { rank: 7, name: 'Matthew' },
    { rank: 8, name: 'Emma' },
    { rank: 9, name: 'Oliver' },
    { rank: 10, name: 'Charlotte' },
  ];

  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={2} sm={2} lg={2} mb={2}>
            <MKTypography variant="h3">Rank</MKTypography>
            <MKTypography variant="h3">1</MKTypography>
          </Grid>
          <Grid item xs={10} sm={10} lg={10}>
            {rankData.map((data, key) => (
              <RankCard
                key={key}
                variant="gradient"
                color={prefersDarkMode ? 'light' : 'dark'}
                bgColor={prefersDarkMode ? 'dark' : 'light'}
                icon={data.rank}
                title={data.name}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default Leaderboard;
